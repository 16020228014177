@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;500;600&display=swap');

/* Main colors */
:root {
  --dark-grey: #24242a;
  --light-brown: #ffdca8;
  --white: #ffffff;
  --hover: #6366f1;
  --active: #575af3;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 7px;
  background: var(--hover);
  overflow: hidden;
  transform-origin: 0%;
  z-index: 101;
}

*,
:after,
:before {
  box-sizing: border-box;
 
}

body {
  margin: 0;
  overflow: scroll;
}

/* Typography */

h2 {
  font-weight: 800;
  text-align: center;
}

p {
  font-family: 'Karla', sans-serif;
}

.head {
  font-size: 36px;
  font-family: 'Bebas Neue', cursive;
}

div h1 span {
  letter-spacing: 0.4px;
}

.course-container h2.browse-text {
  letter-spacing: 1.1px;
  }
  
  div h2.learning-text {
    letter-spacing: 1.1px;
  }
  
  div h2.customer-text {
    letter-spacing: 1.1px;
  }


/* scrollbar */
html::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}
html::-webkit-scrollbar-thumb {
  background: var(--dark-grey);
  border-radius: 16px;
  box-shadow: inset 2px 2px 2px hsl(0deg 0% 100% / 25%),
    inset -2px -2px 2px rgb(0 0 0 / 25%);
}

html {
  font-family: 'Bebas Neue', cursive;
}

/* Navigation */

.navlink {
  font-size: 29px;
  color: var(--white);
  border: 2px solid var(--dark-grey);
}

#header-section {
  background-color: var(--dark-grey);
}


.logo {
  transition: all 0.4s;
}

.logo:hover {
  display: fixed;
  transform: scale(1.1, 1.1);
}

h3.hovered-text {
  cursor: pointer;
  transition: all 0.4s;
}

h3.hovered-text:hover {
  letter-spacing: 1.5px;
  text-decoration: underline 2px solid var(--hover);
}

/* Header */

#hamburger-btn {
  border-radius: 10px;
  border: 1px solid var(--white);
}

svg {
  border-radius: 5px;
  background: var(--white);
  color: var(--white);
}

header.header {
  border: 1px solid var(--dark-grey);
  background-color: var(--white);
}

.block-intro {
  font-size: 68px;
  font-family: 'Bebas Neue', cursive;
}

.block-intro-second {
  font-size: 62px;
  display: block;
}

.dash {
  padding: 5px;
}

a {
  font-family: 'Bebas Neue', cursive !important;
}

.demo-btn {
  font-size: 25px;
  color: var(--dark-grey);
  border: 1px solid var(--dark-grey);
  transition: all 0.4s;
}

.demo-btn:hover {
  background-color: var(--hover);
  color: white;
}

.demo-btn:active {
  background-color: var(--active);
}

.demo-hover {
  border: 1px solid var(--white);
  border-radius: 10px;
  transition: all 0.4s;
}

.demo-hover:hover {
  background-color: var(--hover);
}


/*Image wrapper animation */

img#banner-image {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('Images/cloud-illustration-indigo-400.svg');
  background-repeat: no-repeat;
  background-size: cover;
  animation: expand-fade-in 1.5s ease-out;
  animation-fill-mode: both;
  border-radius: 10px;
  background-color: var(--dark-grey);
}

.title {
  text-transform: uppercase;
  margin: 0;
  text-transform: uppercase;
  text-align: center;
  animation: fade-in 1.5s ease-out;
  animation-fill-mode: both;
  color: var(--dark-grey);
}

@keyframes expand-fade-in {
  from {
    opacity: 0%;
    width: 0%;
  }
  to {
    opacity: 100%;
    width: 100%;
  }
}

@keyframes fade-in {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}


.learning-text span {
  color: var(--hover);
}


/* Footer */

.social-icon {
  width: 50px;
  font-size: 22px;
  transition: all 0.4s;
  border-radius: 10px;
  background-color: var(--white);
  color: var(--dark-grey);
}

.social-icon:hover {
  display: block;
  background-color: var(--hover);
  border: 1px solid var(--white);
  rotate: 15deg;
}

.rounded-shape {
  border-radius: 10px !important;
}

button.slick-arrow {
  display: fixed;
}

h2.project-text span {
  color: var(--hover);
}

#footer-section {
  margin: 0 auto;
  border-radius: 10px;
  padding: 50px;
  border: 1px solid var(--dark-grey);
}

/* Form */

#form-border {
  border: 1px solid var(--dark-grey);
  border-radius: 10px;
}

#form-button {
  color: var(--white);
}

#form-button:hover {
  background-color: var(--white);
  color: #24242a;
}

#menuButton h3.card-title {
  line-height: 1.2px;
  letter-spacing: 1.3px;
  color: var(--hover);
  font-size: 36px;
}

#menuButton img.img-card {
  border: 1px solid var(--dark-grey);
  border-radius: 10px;
}

/* Theme toggle */

#light {
  background-color: #ffffff;
}

#dark {
  background-color: var(--dark-grey);
}

#light.app {
  color: var(--dark-grey);
  background-color: var(--white);
}

/* Dark Theme - Landing Page */

#dark.app,
#dark section.ceo-section,
#dark section.client-section {
  color: var(--white);
  background-color: var(--dark-grey);
}

#dark span.block-intro.title,
#dark span.block-intro-second.title,
#dark p.banner-text,
#dark h2.browse-text,
#dark h2.learning-text,
#dark footer#footer-section h3,
#dark footer#footer-section p,
#dark div.ceo-text,
#dark p.ceo-paragraph,
#dark h2.project-text,
#dark p.project-text-secondary,
#dark p.project-text {
  color: var(--white);
}

#dark button.slick-prev:before,
#dark button.slick-next:before {
  color: var(--hover);
}

#dark div.ceo-text-name {
  color: var(--hover);
}

#dark div.client-section-div {
  background-color: var(--white);
  border: 1px solid var(--white);
  border-radius: 10px;
}

#dark img#logos-image {
  border-radius: 15px;
}

#dark button {
  border: 1px solid var(--white);
  color: var(--white);
}
#dark a.demo-btn,
#dark div.learning-content-div {
  color: var(--white);
  background-color: var(--dark-grey);
  border: 1px solid var(--white);
}

#dark div.course-div{
  margin: 10px;
  background-color: var(--white);
}



#dark a.demo-btn:hover {
  background-color: #575af3;
}

/* Dark theme - Form */

#dark div#form-border{
  border: 1px solid var(--white);
}

#dark .contact-page label,
#dark .contact-page p,
#dark .contact-page h3,
#dark .contact-page a,
#dark .contact-page span{
  color: var(--white);
}

#dark div#menuButton.is_active:after{
border-top: 30px solid var(--active)
}


div.react-switch-bg{
  background-color: var(--hover) !important;
}
.switch {
  padding-left: 50px;
}

#form-container {
  font-family: 'Karla', sans-serif;
}

#form-container h3 {
  font-family: 'Bebas Neue', cursive;
}

#form-container label {
  font-size: 16px;
}

#form-container a {
  font-size: 16px;
}

#form-container #form-button {
  font-family: 'Bebas Neue', cursive;
}

.hamburger-menu-hidden {
  background-color: #24242a !important;
}

/* Dark Theme News */
#dark h2.news-title,
#dark h4.data-date, 
#dark p.data-header{
  color: var(--white);
}

#dark .contact-page label,
#dark .contact-page p,
#dark .contact-page h3,
#dark .contact-page a,
#dark .contact-page span{
  color: var(--white);
}

#dark div.news-section-box{
  border: 1px solid var(--white);
}

/* Filter, Coursepage */

.filter-result {
    background-color: var(--white);
    margin-top: -24px;
}

div.course-div{
  margin: 10px;
 border: 1px solid var(--dark-grey);
}

div.date-course-text{
  font-size: 16px;
}


/* News Page */

div.news-container{
  padding: 160px;
  width: 100%;
}

div.news-box {
  text-align: center;
  width: 75%;
  margin: 0 auto;
  border-radius: 10px;
  padding: 20px;
}

div.news-section-box {
  border: 1px solid var(--dark-grey);
  border-radius: 10px;
}

div.news-section-box {
  padding: 8px;
  margin: 20px auto;
}

h2.news-title {
  text-align: center;
  letter-spacing: 1.2px;
}

div.btn-container button.demo-btn{
 font-size: 18px;
 width:82px;
 margin: 0 auto;
 cursor: pointer;
}

h2.data-title {
  padding: 5px;
  font-size: 25px;
  color: var(--hover);
} 

h4.data-date {
  font-size: 19px;
  padding: 5px;
}

p.data-header {
  padding: 5px;
}

img.data-img {
  padding: 10px;
  width: 200px;
  height: auto;
  margin: 0 auto;
  cursor: pointer;
  transition: all 0.4s ease-in;
}

img.data-img:hover{
  scale: 1.2;
  margin-bottom: 8px;
}

@media (max-width: 767px) {
  div.news-container {
    margin-top: 120px;
    padding: 0;
}
#footer-section {
  margin: 30px auto;
}
}

.view-more-btn{
  border-radius: 10px;
  border: 1px solid var(--dark-grey);
  cursor: pointer;
  margin: 0 auto;
  font-size: 20px;
  width: 99px;
  background-color: var(--hover);
  color: var(--white);
  transition: all 0.4s;
}

.view-more-btn:hover{
  background-color: var(--white);
  color: var(--dark-grey);
}

.css-1yjjitx-MuiSwitch-track {
 background-color: white !important;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

.header-button {
  cursor: pointer;
  display: inline-block;
  position: relative;
  z-index: 9;
  text-transform: uppercase;
  letter-spacing: 1.4px;
  padding: 11.5px 12.5px;
  text-decoration: none !important;
  overflow: hidden;
  vertical-align: middle;
}

.content-header {
  padding-top: 120px;
}

#mobile-menu {
  background-color: #fff;
}

button.slick-prev:before,
button.slick-next:before {
  color: var(--dark-grey);
  font-size: 45px;
}

button.slick-prev,
button.slick-next {
  z-index: 99;
  top: 40%;
}

button.slick-next {
  z-index: 99;
  right: 0;
}



header.header {
  z-index: 100;
}

.logos-container {
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-bottom: 50px;
}

.logos-container div {
  -webkit-animation: scroll 40s linear infinite;
  -moz-animation: scroll 40s linear infinite;
  animation: scroll 40s linear infinite;
  display: flex;
  width: 100%;
}

.logos-container div img {
  height: 100px;
  padding-right: 30px;
  padding-left: 30px;
  max-width: 100%;
}

.logos-container div img.no-padding {
  padding: 0;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 7));
  }
}


@-webkit-keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 7));
  }
}

@-moz-keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 7));
  }
}

.Card-slider h3 {
  position: relative;
  top: 30%;
}

div#menuButton {
  margin-right: 16px;
  position: relative;
  text-align: center;
  outline: none;
}

div#menuButton img {
  width: 100%;
  max-width: 100%;
  border: 3px solid transparent;
}

div#menuButton h3 {
  display: flex;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  color: #fff;
  justify-content: center;
  align-items: center;
}

div#menuButton.is_active img {
  position: relative;
  border: 0;
  border: 3px solid rgb(99, 102, 241);
}

div#menuButton.is_active svg {
  visibility: visible;
  color: rgb(99, 102, 241);
}

div#menuButton svg {
  position: absolute;
  bottom: 0;
  visibility: hidden;
  font-size: 3rem;
  left: calc(50% - 1.5rem);
  right: 0;
  fill: currentColor;
  width: 1em;
  height: 1em;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}


.filter-result {
  background-color: #2d1837;
  margin-top: -24px;
}

.filter-result button.slick-prev:before,
.filter-result button.slick-next:before {
  color: var(--dark-grey);
}

div#menuButton.is_active:after {
  content: '';
  position: relative;
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 30px solid var(--dark-grey);
  clear: both;
  bottom: -24px;
}

@media screen and (max-width: 991px) {
  nav.nav-mobile {
    top: 20px;
  }
}

@media screen and (max-width: 820px) {

  span.block-intro.title{
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

    p.ceo-paragraph{
      margin-top: 20px;
    }
  
}


@media screen and (min-device-width: 921px) and (max-device-width: 1023px) { 
  .course-container {
    margin-top: 190px;
  }
}



@media screen and (max-width: 1032px) {
  .app {
    margin: 0 auto;
    padding: 10px;
    text-align: center !important;
  }

}

@media screen and (max-width: 920px) {
  img.logo-footer {
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
  img#banner-image {
    margin-bottom: 180px;
  }
  div.first-btn {
    padding-top: 10px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  span.block-intro.title{
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
}



@media screen and (max-width: 430px) {


  header.header {
    max-width: 420px;
  }

  img.logo-footer {
    margin: 0 auto;
  }
  div.social-section {
    display: flex;
    justify-content: center;
  }

  span.block-intro.title {
    margin-top: 30px;
    flex-direction: column;
    line-height: 0.6;
  }

  span.block-intro-second.title {
    padding: 10px;
    margin-top: 15px;
    line-height: 0.8;
  }
}
